// Functions for Walt Disney World searches; v2.0.0
// Author: Tom Nelson, December 2015

// -------------------------------------------------------------------------------------------------------------------

// Quick quote panel; homepage only

// Determine if user is a travel agent
var agent = ($('body').attr('data-agent') == 'true');

// Valid Walt Disney World hotel search
function hotel(inbound) {
    // Is the return journey after the last operational Walt Disney World hotel date?
    if (inbound.getTime() > maxdate.getTime()) { warning(literal('search.sorry-max-date', { maxDate: moment(maxdate).format('LL') }), literal('search.hotel-not-on-sale')); GA.sendEvent('Alert', 'Search', 'Hotel Out of Range'); return false; }
    // Otherwise valid
    return true;
}

// Valid Walt Disney World ticket search
function IsTicketAvailableOutsideMaxDate(inbound) {
    var datalocale = $('#quick-quote').attr('data-locale');
    // Is the return journey after the last operational Walt Disney World hotel date?
    if (inbound.getTime() > maxdate.getTime() && (datalocale == 'EUR')) {
        warning(literal('search.tickets-not-on-sale-title', { maxDate: moment(maxdate).format('LL') }), literal('search.tickets-not-on-sale-body'));
        GA.sendEvent('Alert', 'Search', 'Ticket Out of Range');
        return false;
    }
    // Otherwise valid
    return true;
}

// Check duration is valid for holiday type
function duration(type, nights) {
    // Determine minimum duration
    var minduration = 1;
    switch (type) { case 'flights': case 'package': $('#quick-quote').attr('data-locale') == 'EUR' ? minduration = 1 : minduration = 5; }
    // Is duration too short?
    if (nights < minduration) { warning(literal('stay-too-short-title'), literal('stay-too-short-message')); GA.sendEvent('Alert', 'Search', 'Below Minimum Stay Duration'); return false; };
    // Otherwise valid
    return true;
}

// Get dates for validation
function valid(type) {
    // Get outbound and inbound dates and convert to date object
    var monthyear = $('#month').val();
    var year = parseInt(monthyear.split('^')[1]);
    var month = parseInt(monthyear.split('^')[0]) - 1; // Months are zero-indexed in JavaScript
    var day = parseInt($('#day').val());
    var nights = parseInt($('#nights').val())
    var outbound = new Date(year, month, day);
    var inbound = new Date(new Date(+outbound + (nights * 864e5)).toDateString()); // Convert to string to ensure 00:00:00
    var minimum = new Date(+new Date + 6048e5); // Bookings must be a minimum of 7 days (604800000 milliseconds) from today for flights/packages
    var minimumdays = 7;
    // Override miminum for ticket only
    switch (type) {
        case 'tickets': minimum = new Date(+new Date + 2592e5); minimumdays = 3; submit = true; break; // Bookings must be a minimum of 3 days (259200000 milliseconds) from today for ticket only
    };
    // Is the outbound journey before a temporary closure end date?
    var datalocale = $('#quick-quote').attr('data-locale');
    var tempClosure = $('#quick-quote').attr('data-temporary-closure-end');
    if (tempClosure != null) {
        var tempClosureDate = moment(tempClosure, "DD/MM/YYYY").toDate();
        if (outbound.getTime() < tempClosureDate.getTime()) {
            if (datalocale == 'EUR') {
                warning(literal('search.sorry-min-package-date'), literal('search.package-min-date'));
            }
            else {
                if (type == 'tickets') {
                    warning(literal('search.sorry-min-tickets-date'), literal('search.tickets-min-date'));
                } else {
                    warning(literal('search.sorry-min-date'), literal('search.hotel-min-date'));
                }
            }
            return false;
        }
    }
    // Override minimum for travel agents
    if (agent) { minimum = new Date(+new Date + 1469e6); minimumdays = 17; } // Bookings must be a minimum of 17 days (1469000000 milliseconds) from today for all product types
    // Check eligible departure
    if (minimum.getTime() > outbound.getTime()) { warning(literal('search.within-x-days', { days: minimumdays }), literal('search.within-x-days-message')); GA.sendEvent('Alert', 'Search', 'Departure Within ' + minimumdays + ' Days'); return false; }
    // Validate pararmeters for hotel-inclusive holidays; ticket only searches are excluded from these checks
    if (type != 'tickets') {
        // Maximum of 8 pax online, excluding infants
        var infants = $('.ages select:has(option[value=1]:selected):visible').length;
        var passengers = parseInt($('#adults').val()) + parseInt($('#children').val()) - infants;
        var guests = "eight";
        if (passengers > 8) { warning(literal('search.party-more-guests', { nGuests: guests }), literal('search.party-more-x-hotel')); GA.sendEvent('Alert', 'Search', 'Occupancy: 9+ Guests'); return false; }
        // Maximum of 1 infant online
        if (infants > 1) { warning(literal('search.party-more-1-infant'), literal('search.party-more-1-infant-message')); GA.sendEvent('Alert', 'Search', 'Occupancy: 2+ Infants'); return false; }
        // Check if hotel is available
        journey = hotel(inbound);
        // If hotel is available then check duration requirements for selected option
        if (journey) { journey = duration(type, nights); }
        // Is journey okay?
        if (!journey) { return false; };
        // If valid journey then show intermission
        intermission();
        // Pause carousel
        billboard.slick('slickPause');
    }

    if (type == 'tickets') {
        var infants = $('.ages select:has(option[value=1]:selected):visible').length;
        var ageTwo = $('.ages select:has(option[value=2]:selected):visible').length;
        var passengers = parseInt($('#adults').val()) + parseInt($('#children').val()) - ageTwo - infants;
        if (passengers > 10) {
            warning(literal('search.party-more-guests', { nGuests: '10' }), literal('search.party-more-x-ticket'));
            return false;
        }
        // Check if tickets are available
        journey = IsTicketAvailableOutsideMaxDate(inbound);
        // Is journey okay?
        if (!journey) { return false; };
    }
    // Post form
    return true;
}

// Set button content
function button(type) {
    var label = literal('search.' + type);
    $('input.button').val(label);
}

// Return hotel category based on code
function category(code) {
    return literal('labels.hotelCategory.' + code);
}

// Show/hide accessible rooms checkbox
function accessible(type) {
    // Get checkbox
    var accessible = $('.accessible');
    // Suppress for tickets option
    if (type == 'tickets') { accessible.addClass('hide'); } else { accessible.removeClass('hide'); }
}

// -------------------------------------------------------------------------------------------------------------------

// On ready
$(document).ready(function () {

    // Catch form post
    $('#form').submit(function (event) {
        // Prevent DOM from unloading before we have fired the tracking code
        event.preventDefault();
        // Set this form to be a variable
        form = this;
        // Get transport method
        var type = $('input:radio:checked').val().toLowerCase();
        // Valid dates?
        if (valid(type)) {
            // Track query type via Google Analytics; set delay to force interstitial
            var query;
            var pageview;
            var analytics;
            // Set delay; default equals 2 seconds after image has loaded
            delay = 2000;
            // Submission varies by type
            switch (type) {
                case 'flights': query = 'Flights + Hotel + Ticket'; pageview = 'flights'; delay = 500; break; // Override for flight inclusive
                case 'package': query = 'Hotel + Ticket'; pageview = 'hotel-ticket'; break;
                case 'hotel': query = 'Hotel Only'; pageview = 'hotel'; break;
                case 'tickets': query = 'Ticket Only'; pageview = 'tickets'; delay = 250; break;
            }
            // Capture search data and log in Google Analytics
            searches(type, query, brand, null)
            // Add _trackEvent to the _gaq
            GA.sendEvent('Book', 'Search (UK)', query);
            // Add virtual pageview to enable funnel reporting
            _gaq.push(['_trackPageview', '/walt-disney-world/book/search/' + pageview + '/']);

            // Add the call to the _gaq queue; delay form submission to allow _trackEvent to complete and interstitial to load
            submitForm();
        }
    });

});
